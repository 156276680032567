import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "@idev/icons/css/all.css";
import { ReactPWAInstallProvider } from '@idev/components';
import { Provider } from 'react-redux';
import {
  accountStore,
  companyStore,
  screenSizeStore,
  authSliceStore,
  companySliceStore,
  fileSliceStore,
  bankSliceStore,
  customerSliceStore,
  billSliceStore,
  receiptSliceStore
} from '@idev/redux'
import { configureStore } from '@reduxjs/toolkit';

const store = configureStore({
  reducer: {

    ...authSliceStore,
    ...companySliceStore,
    ...fileSliceStore,
    ...bankSliceStore,
    ...customerSliceStore,
    ...billSliceStore,
    ...receiptSliceStore,

    ...accountStore,
    ...companyStore,
    ...screenSizeStore
  },
  devTools: true,
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ReactPWAInstallProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </ReactPWAInstallProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
