import * as React from 'react';
// import logo from './logo.svg';
import './App.css';
import { BrowserRouter } from "react-router-dom";

import { useDispatch } from "react-redux";
import { refreshTokenFetch } from "@idev/redux";
import { MainRouter } from "./routes/main";
import { ThemeProvider } from '@idev/ui';
import theme from './theme';



function App() {
  

  const dispatch = useDispatch();
  const signInData = JSON.parse(localStorage.getItem("sign-in"))
  React.useEffect(() => {
    if (signInData) {
      dispatch(refreshTokenFetch({ refreshToken: signInData.refreshToken }));
    }
console.log(process.env);
  }, [signInData]);
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <MainRouter></MainRouter>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
