import * as React from 'react'
import { Routes, Route } from 'react-router-dom';
import { MainView } from '@idev/feature-main';
import { LoadingView } from '@idev/feature-loading'
import { VerticalTabs } from '@idev/components'
import { Navigate } from 'react-router-dom'
import { useSelector } from "react-redux";
import { selectRefreshTokenSlice } from "@idev/redux";
import {HomeView as Home} from '@idev/feature-home'
import {LoginView as Login} from '@idev/feature-login'
import {NoMatchView as NoMatch} from '@idev/feature-no-match'
import {SettingsView as Settings} from '@idev/feature-settings'
import {BillView as Bill} from '@idev/feature-bill'

const tabs = [
    {
        id: "home",
        icon: "fa-duotone fa-house",
        link: "/home",
        type: "page",
        name: "หน้าแรก",
    },
    // {
    //     id: "pos-system",
    //     icon: "fa-duotone fa-boxes-stacked",
    //     link: "/home/pos-system",
    //     type: "page",
    //     name: "ระบบสต็อกสินค้า",
    // },
    // {
    //     id: "pawning-system",
    //     icon: "fa-duotone fa-hand-holding-box",
    //     link: "/home/pawning-system",
    //     type: "page",
    //     name: "ระบบรับจำนำ",
    // },
    // {
    //     id: "repair-system",
    //     icon: "fa-duotone fa-screwdriver-wrench",
    //     link: "/home/repair-system",
    //     type: "page",
    //     name: "ระบบรับซ่อม",
    // },
    // {
    //     id: "member-management-system",
    //     icon: "fa-duotone fa-users-gear",
    //     link: "/home/member-management-system",
    //     type: "page",
    //     name: "ระบบจัดการสมาชิก",
    // },
    {
        id: "bill",
        icon: "fa-duotone fa-file-invoice",
        link: "/home/bill",
        type: "page",
        name: "ใบวางบิล",
    },
    {
        id: "setting",
        icon: "fa-duotone fa-gear",
        link: "/home/setting",
        type: "page",
        name: "ตั้งค่า",
    },
];

// const Home = React.lazy(() => import("../views/home"));
// const Login = React.lazy(() => import("@idev/feature-login/src/view/login"));
// const Home = React.lazy(() => import("@idev/feature-home/src/view/home"));
// const NoMatch = React.lazy(() => import("@idev/feature-no-match/src/view/no-match"));
// const Settings = React.lazy(() => import("@idev/feature-settings/src/view/setting"));
// const POSSystem = React.lazy(() => import("../views/pos-system"));
// const PawningSystem = React.lazy(() => import("../views/pawning-system"));
// const RepairSystem = React.lazy(() => import("../views/repair-system"));
// const MemberManagementSystem = React.lazy(() => import("../views/member-management-system"));

export function MainRouter() {
    const refreshTokenSlice = useSelector(selectRefreshTokenSlice);

    return (<Routes>
        <Route
            path="/"
            element={<MainView></MainView>}
        >
            <Route
                path=""
                Component={() => {


                    if (refreshTokenSlice.loadingStatus === "loading") {
                        return (<LoadingView></LoadingView>)
                    } else if (refreshTokenSlice.loadingStatus === "error") {
                        return (<Navigate to="/login" />)
                    } else if (refreshTokenSlice.loadingStatus === "not loaded") {
                        return (<Navigate to="/login" />)
                    } else if (refreshTokenSlice.loadingStatus === "loaded") {
                        return (<Navigate to="/home" />)
                    }
                }}
            />
            <Route
                path="login"
                element={
                    <React.Suspense fallback={<LoadingView></LoadingView>}>
                        <Login></Login>
                    </React.Suspense>
                }
            />
            <Route
                path="home"
                element={
                    <React.Suspense fallback={<LoadingView></LoadingView>}>
                        <VerticalTabs tab={tabs}></VerticalTabs>
                    </React.Suspense>}
            >
                <Route path="" Component={Home} />
                {/* <Route path="pos-system" Component={POSSystem} /> */}
                {/* <Route path="pawning-system" Component={PawningSystem} /> */}
                {/* <Route path="repair-system" Component={RepairSystem} /> */}
                {/* <Route path="member-management-system" Component={MemberManagementSystem} /> */}
                <Route path="bill" Component={Bill} />
                <Route path="setting" Component={Settings} />
            </Route>
            <Route path="*" Component={NoMatch} />
        </Route>
    </Routes>)
}